import { defineMessages } from 'react-intl';

export default defineMessages({
  noBookings: {
    id: 'CustomerAccount.noBookings',
    defaultMessage: 'No bookings have been made by this customer.',
  },
  bookingsTab: {
    id: 'CustomerAccount.bookingsTab',
    defaultMessage: 'Bookings',
  },
  savedPassengersTab: {
    id: 'CustomerAccount.savedPassengersTab',
    defaultMessage: 'Saved passengers',
  },
});
