import React, { FunctionComponent } from 'react';
import { css } from 'aphrodite/no-important';

import { pushToDataLayer } from '@contactcentre-web/utils/tracker';

import PageContainer from '../PageContainer';

import { getFilteredBookings } from './utils';
import Booking from './components/BookingsTable/Booking';
import BookingsTableHeader from './components/BookingsTable/BookingsTableHeader';
import BookingsFilterHeader from './components/BookingsFilterHeader/BookingsFilterHeader';
import { CustomerBooking, ColumnName, AppliedSort, FilterType } from './types';
import styles from './styles';

interface Props {
  bookings: Array<CustomerBooking>;
  onSort?: (columnName: ColumnName) => void;
  appliedSort?: AppliedSort;
  footerRenderer?: (numberOfBookings: number) => JSX.Element;
  addFilters: (filters: Set<FilterType>) => void;
  removeFilter: (filter: FilterType) => void;
  appliedFilters: Set<FilterType>;
}

const BookingSearchResults: FunctionComponent<Props> = ({
  bookings,
  onSort,
  appliedSort,
  footerRenderer,
  addFilters,
  removeFilter,
  appliedFilters,
}) => {
  React.useEffect(() => {
    pushToDataLayer({
      event: 'ga_search_results',
      ga_event: {
        category: 'search results',
        action: bookings.length,
        value: 0,
        nonInteraction: 0,
      },
    });
    pushToDataLayer({
      event: 'ga4_search_results',
      event_name: 'search_results',
      event_params: {
        number_of_results: bookings.length,
      },
    });
  }, []);

  const filteredBookings = getFilteredBookings(bookings, appliedFilters);
  const bookingsList = appliedFilters.size > 0 ? filteredBookings : bookings;

  return (
    <PageContainer styleSheet={styles.container}>
      <section className={css(styles.container)} data-testid="booking-search-results">
        <BookingsFilterHeader
          bookings={bookings}
          filteredBookings={filteredBookings}
          bookingsCount={bookingsList.length}
          addFilters={addFilters}
          removeFilter={removeFilter}
          appliedFilters={appliedFilters}
        />
        <div>
          <BookingsTableHeader selectedSortOptions={appliedSort} onChange={onSort} />
          <div className={css(styles.bookingListContainer)}>
            {bookingsList.map((booking, key) => (
              <Booking key={key} {...booking} />
            ))}
            {footerRenderer && footerRenderer(bookingsList.length)}
          </div>
        </div>
      </section>
    </PageContainer>
  );
};

export default BookingSearchResults;
