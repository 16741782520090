import { StyleSheet } from 'aphrodite/no-important';
import { spacers, palettes } from '@trainline/depot-web/css/themes/trainline';

export default StyleSheet.create({
  container: {
    marginTop: spacers.dp_s32,
  },
  tabs: {
    margin: '0 auto',
    position: 'relative',
    border: 0,
    justifyContent: 'center',

    ':nth-child(1n) > button': {
      flex: 'unset',
    },

    '::after': {
      content: '""',
      position: 'absolute',
      height: '1px',
      width: '100%',
      backgroundColor: palettes.dp_app_border_base,
    },
  },
});
