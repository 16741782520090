import React, { useEffect, useRef } from 'react';
import { css } from 'aphrodite/no-important';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import { StatusMessage, Tabs, MessageMood } from '@trainline/depot-web';

import BookingSearchResults from '@contactcentre-web/common/BookingSearchResults';
import ListSummary from '@contactcentre-web/common/ListSummary/ListSummary';
import Loader from '@contactcentre-web/common/Loader';
import PageContainer from '@contactcentre-web/common/PageContainer';
import CustomerPassengers from '@contactcentre-web/customer-passengers/CustomerPassengers';

import messages from './messages';
import { actions, selectors } from './module';
import styles from './styles';

export interface Props {
  selectedCustomer: { id: string; forename: string; surname: string };
}

export const CustomerAccount = ({ selectedCustomer }: Props) => {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const location = useLocation();
  const history = useHistory();
  const selectedTab = new URLSearchParams(location.search).get('tab') ?? undefined;
  const { id: customerId, forename, surname } = selectedCustomer;

  const bookingSummaries = useSelector(selectors.selectSortedBookingSummaries);
  const selectedSortOptions = useSelector(selectors.selectCustomerBookingSummariesSortOptions);
  const loading = useSelector(selectors.selectLoading);
  const appliedFilters = useSelector(selectors.selectAppliedFilters);

  const loadCustomerBookingSummariesRef = useRef(() => dispatch(actions.load(customerId)));

  useEffect(() => {
    loadCustomerBookingSummariesRef.current();
    return () => {
      dispatch(actions.reset());
    };
  }, [dispatch, customerId]);

  if (loading) {
    return (
      <div className={css(styles.container)}>
        <Loader />
      </div>
    );
  }

  if (!bookingSummaries) {
    return null;
  }

  const bookingSummariesCount = bookingSummaries.length;
  if (bookingSummariesCount === 0) {
    return (
      <PageContainer styleSheet={styles.container}>
        <StatusMessage mood={MessageMood.INFO} body={formatMessage({ ...messages.noBookings })} />
      </PageContainer>
    );
  }

  if (bookingSummariesCount === 1) {
    return (
      <Redirect
        to={`/customers/${bookingSummaries[0].customerId}/bookings/${bookingSummaries[0].orderReference}`}
      />
    );
  }

  const onTabChange = (key: string) => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.set('tab', key);
    history.push({ search: searchParams.toString() });
  };

  return (
    <Tabs
      items={[
        {
          key: 'customer-account-bookings',
          label: formatMessage({ ...messages.bookingsTab }),
          content: (
            <BookingSearchResults
              bookings={bookingSummaries}
              onSort={(sortPropName) => dispatch(actions.sort(sortPropName))}
              appliedSort={selectedSortOptions}
              footerRenderer={(bookingsCount) => (
                <ListSummary
                  type="bookings"
                  total={bookingsCount}
                  name={`${forename} ${surname}`}
                />
              )}
              addFilters={(filters) => dispatch(actions.filtersAdd(filters))}
              removeFilter={(filter) => dispatch(actions.filterRemove(filter))}
              appliedFilters={appliedFilters}
            />
          ),
        },
        {
          key: 'customer-account-saved-passengers',
          label: formatMessage({ ...messages.savedPassengersTab }),
          content: <CustomerPassengers />,
        },
      ]}
      onTabChange={onTabChange}
      className={css(styles.tabs)}
      defaultActiveTabKey={selectedTab}
    />
  );
};

CustomerAccount.displayName = 'CustomerAccount';

export default CustomerAccount;
