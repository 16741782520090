import { StyleSheet } from 'aphrodite/no-important';
import { palettes, spacers, typography } from '@trainline/depot-web/css/themes/trainline';

export default StyleSheet.create({
  bookingListContainer: {
    boxShadow: `0px 4px 8px 0 ${palettes.dp_comp_box_shadow}`,
    paddingBottom: spacers.dp_s32,
    fontSize: typography.dp_small_font_size_m,
  },
  container: {
    marginTop: spacers.dp_s32,
  },
});
